.main {
}

.header {
  width: 100vw;
  padding: 14px;
  border-bottom: 1px solid gainsboro;
  display: none;
}

.header .container {
  max-width: 1300px;
  margin: 0 auto;
}

.thumbnail {
  display: block;
  margin: 0 auto;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 8px;
}

.thumbnail:hover {
  opacity: 0.7;
}

.footer {
  position: fixed;
  bottom: 10px;
  left: 0px;
  width: 100vw;
}

.main-container {
  margin-top: 20px;
}

iframe {
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .main-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .upload {
    display: none;
  }

  .footer {
    display: none;
  }

  .header {
    display: block;
  }
}
