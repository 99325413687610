.query-text h2 {
  font-size: 22px;
  color: #3f3f3f;
  margin-top: 24px;
  margin-bottom: 2px;
}

.query-text p {
  color: slategray;
  font-size: 14px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .query-text h2 {
    font-size: 16px;
    line-height: 1;
    margin-top: 18px;
    margin-bottom: 6px;
  }

  .query-text p {
    color: slategray;
    font-size: 12px;
    margin-bottom: 16px;
  }
}
