.search-results {
  flex-shrink: 0;
  width: 370px;
  font-size: 16px;

  padding: 18px 14px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 8px;
  min-height: 590px;
  max-height: 590px;
  overflow-y: auto;
}

.search-results-container {
  width: fit-content;
}

.search-results h2 {
  font-size: 22px;
  color: #393939;
  text-align: center;
}

.list-item,
.search-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 5px;
  cursor: pointer;
  margin-bottom: 14px;
}

.list-item:last-of-type,
.search-item:last-of-type {
  margin-bottom: 0;
}

.list-item p,
.search-item p {
  /* white-space: nowrap; */
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.list-item span {
  background-color: #cee8fe;
  color: #2196f3;
  display: inline-block;
  padding: 0px 8px;
  border-radius: 3px;
  font-weight: 500;
}

.not-found {
  text-align: center;
  color: gray;
  margin-top: 16%;
}

@media only screen and (max-width: 600px) {
  .search-results {
    width: 100%;
  }

  .search-results h2 {
    font-size: 16px;
  }

  .search-results-container {
    width: 100%;
  }
}
