.upload,
.videos-container {
  flex-shrink: 0;
  width: 340px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  text-align: center;
  padding: 16px 0px;
  border-radius: 14px;
}

.upload h2,
videos-container h2 {
  font-size: 22px;
  color: #434343;
}

.upload p {
  font-size: 14px;
  line-height: 1.2;
  color: darkslategray;
  margin-top: 16px;
  margin-bottom: 16px;
}

.videos {
  max-height: 470px;
  overflow-y: auto;
  padding: 0px 16px;
}
